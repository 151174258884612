import { memo, useCallback, useEffect } from "react"
import { connect } from "react-redux"
import { always, applySpec, compose, cond, defaultTo, equals, filter, map, pluck, prop, propEq } from "ramda"
import { useMutation } from "urql"

import { Input, Modal, Switch, VerticalTabs, Tooltip } from "@ninjaone/components"
import { RegularInfoCircleIcon } from "@ninjaone/icons"
import tokens from "@ninjaone/tokens"
import { getTicketingForms, getTicketRuleById } from "js/includes/common/client"
import { useForm, useMountedState } from "js/includes/common/hooks"
import {
  localized,
  localizationKey,
  reportErrorAndShowMessage,
  showSuccessMessage,
  validations,
} from "js/includes/common/utils"
import Loading from "js/includes/components/Loading"
import { Flex } from "js/includes/components/Styled"
import { extractOnChangeValues, validateTime } from "js/includes/ticketing/editor/shared/utils"
import { fetchTicketStatuses as _fetchTicketStatuses } from "js/state/actions/ticketing"

import { createTicketRuleset, updateTicketRuleset } from "./graphql"
import { ConditionReset, ConditionRetrigger, MAX_TIME_ENTRY, TicketCreate } from "./tabs"

const getTicketFormsOptions = compose(
  map(
    applySpec({
      value: prop("id"),
      labelText: prop("name"),
    }),
  ),
  filter(propEq("active", true)),
  defaultTo([]),
)

const getNewModalTitle = cond([
  [equals("SCRIPT_RULE"), always(localizationKey("Add script rule automation"))],
  [equals("CONDITION_RULE"), always(localizationKey("Add condition rule automation"))],
  [equals("ACTIVITY_RULE"), always(localizationKey("Add activity rule automation"))],
])

const getEditModalTitle = cond([
  [equals("SCRIPT_RULE"), always(localizationKey("Edit script rule automation"))],
  [equals("CONDITION_RULE"), always(localizationKey("Edit condition rule automation"))],
  [equals("ACTIVITY_RULE"), always(localizationKey("Edit activity rule automation"))],
])

const validateRetriggerStatus = (value, values) => {
  const success = values.createNewTicket ? true : !!value
  const message = !success ? localized("Required") : ""
  return {
    success,
    message,
  }
}

const TicketRulesetModal = memo(({ unmount, readonly = false, id, fetchRulesetList, type, fetchTicketStatuses }) => {
  const isEditing = !!id
  const isConditionRule = type === "CONDITION_RULE"

  const [ticketForms, setTicketForms] = useMountedState([])
  const [fetchingTicketForms, setFetchingTicketForms] = useMountedState(false)
  const [fetchingRule, setFetchingRule] = useMountedState(false)

  const buildValidations = useCallback(
    resetTimeUnit => ({
      name: validations.required,
      ticketFormId: validations.required,
      status: validations.required,
      ...(isConditionRule && {
        resetTimeValue: value => {
          if (!value) {
            return { success: true, message: "" }
          }
          return validateTime({ max: MAX_TIME_ENTRY[resetTimeUnit], value })
        },
        resetStatus: validations.required,
        retriggerStatus: validateRetriggerStatus,
      }),
    }),
    [isConditionRule],
  )

  const { values, validation, validateForm, onChange: _onChange, validateFormFields, updateValidate } = useForm({
    fields: {
      enabled: true,
      name: "",
      ticketFormId: null,
      status: null,
      priority: null,
      severity: null,
      tags: [],
      ...(isConditionRule && {
        resetStatus: null,
        resetTimeValue: 0,
        resetTimeUnit: "HOURS",
        createNewTicket: false,
        retriggerStatus: null,
        retriggerTags: [],
        retriggerPriority: null,
        retriggerSeverity: null,
      }),
      type,
      useOwnerAsRequester: false,
    },
    validate: buildValidations("HOURS"),
  })

  const onChange = useCallback(
    (...onChangeArgs) => {
      const newValues = extractOnChangeValues(onChangeArgs)
      if (!newValues) {
        return
      }
      _onChange(newValues)
      if ("resetTimeUnit" in newValues) {
        updateValidate(buildValidations(newValues.resetTimeUnit))
      }
    },
    [_onChange, buildValidations, updateValidate],
  )

  useEffect(() => {
    ;(async () => {
      if (!id) return
      try {
        setFetchingRule(true)
        const { data, error } = await getTicketRuleById(id)
        if (error) throw error

        const { ticketRulesetById } = data

        onChange({
          enabled: ticketRulesetById?.enabled ?? true,
          name: ticketRulesetById?.name ?? "",
          ticketFormId: ticketRulesetById?.conditionTriggerSettings?.ticketFormId ?? null,
          status: ticketRulesetById?.conditionTriggerSettings?.status ?? null,
          priority: ticketRulesetById?.conditionTriggerSettings?.priority ?? null,
          severity: ticketRulesetById?.conditionTriggerSettings?.severity ?? null,
          tags: ticketRulesetById?.conditionTriggerSettings?.tags?.map(tag => ({ label: tag, value: tag })) ?? [],
          useOwnerAsRequester: !!ticketRulesetById?.conditionTriggerSettings?.useOwnerAsRequester,
          ...(isConditionRule && {
            resetStatus: ticketRulesetById?.conditionResetSettings?.status ?? null,
            resetTimeValue: ticketRulesetById?.conditionResetSettings?.time?.value ?? 0,
            resetTimeUnit: ticketRulesetById?.conditionResetSettings?.time?.unit ?? "HOURS",
            createNewTicket: ticketRulesetById?.conditionReTriggerSettings?.createNewTicket ?? false,
            retriggerStatus: ticketRulesetById?.conditionReTriggerSettings?.status ?? null,
            retriggerTags:
              ticketRulesetById?.conditionReTriggerSettings?.tags.map(tag => ({ label: tag, value: tag })) ?? [],
            retriggerPriority: ticketRulesetById?.conditionReTriggerSettings?.priority ?? null,
            retriggerSeverity: ticketRulesetById?.conditionReTriggerSettings?.severity ?? null,
          }),
        })
      } catch (error) {
        reportErrorAndShowMessage(error)
      } finally {
        setFetchingRule(false)
      }
    })()
  }, [id, onChange, setFetchingRule, isConditionRule])

  useEffect(() => {
    ;(async () => {
      try {
        setFetchingTicketForms(true)

        const ticketForms = await getTicketingForms()
        setTicketForms(ticketForms)
      } catch (error) {
        reportErrorAndShowMessage(error, localizationKey("Error fetching ticket forms"))
      } finally {
        setFetchingTicketForms(false)
      }
    })()
  }, [setFetchingTicketForms, setTicketForms])

  useEffect(fetchTicketStatuses, [fetchTicketStatuses])

  const [{ fetching: executingMutation }, executeMutation] = useMutation(
    isEditing ? updateTicketRuleset : createTicketRuleset,
  )

  const {
    enabled,
    name,
    ticketFormId,
    tags,
    status,
    priority,
    severity,
    resetStatus,
    resetTimeValue,
    resetTimeUnit,
    createNewTicket,
    retriggerStatus,
    retriggerTags,
    retriggerPriority,
    retriggerSeverity,
    type: ruleType,
    useOwnerAsRequester,
  } = values

  const handleSubmit = async () => {
    if (validateForm()) {
      const { error } = await executeMutation({
        ruleset: {
          enabled,
          name,
          type: ruleType,
          conditionTriggerSettings: {
            ticketFormId,
            priority,
            severity,
            status,
            tags: pluck("value", tags),
            useOwnerAsRequester,
          },
          ...(isConditionRule && {
            conditionResetSettings: {
              status: resetStatus,
              time: !!resetTimeValue ? { value: resetTimeValue, unit: resetTimeUnit } : null,
            },
            conditionReTriggerSettings: {
              createNewTicket,
              status: retriggerStatus ?? status,
              tags: !createNewTicket ? pluck("value", retriggerTags) : [],
              priority: !createNewTicket ? retriggerPriority : null,
              severity: !createNewTicket ? retriggerSeverity : null,
            },
          }),
          ...(isEditing ? { id } : {}),
        },
      })

      if (!error) {
        showSuccessMessage()
        unmount?.()
        fetchRulesetList?.()
      } else {
        reportErrorAndShowMessage(
          error,
          isEditing ? localizationKey("Error updating ticket ruleset") : localizationKey("Error saving ticket ruleset"),
        )
      }
    }
  }

  const ticketFormsOptions = getTicketFormsOptions(ticketForms)

  const fetching = fetchingTicketForms || fetchingRule
  const ticketCreateError = validation.success.ticketFormId === false || validation.success.status === false
  const conditionResetError = validation.success.resetStatus === false || validation.success.resetTimeValue === false
  const conditionRetriggerError = validation.success.retriggerStatus === false

  return (
    <Modal
      id="ticket-ruleset-modal"
      size="lg"
      unmount={unmount}
      titleGroup={{
        titleToken: isEditing ? getEditModalTitle(type) : getNewModalTitle(type),
        descriptionToken: localizationKey(
          "Configure the settings to be used on ticket creation and updates from conditions.",
        ),
      }}
      buttons={[
        {
          type: "apply",
          labelToken: isEditing ? localizationKey("Save") : localizationKey("Create"),
          onClick: handleSubmit,
          disabled: executingMutation || readonly,
        },
      ]}
    >
      <Flex justifyContent="center" height="450px">
        {fetching ? (
          <Loading />
        ) : (
          <VerticalTabs
            ariaLabel={localized("Edit condition rule")}
            tabs={[
              {
                label: localized("General"),
                hasError: validation.success.name === false,
                required: true,
                renderer: () => (
                  <Flex flexDirection="column" marginTop={tokens.spacing[1]} gap={tokens.spacing[2]}>
                    <Flex flexDirection="row" gap={tokens.spacing[1]} alignItems="center">
                      <Switch
                        disabled={readonly}
                        checked={enabled}
                        onChange={checked => onChange("enabled", checked)}
                        labelToken={localizationKey("Enabled")}
                      />
                      <Tooltip label={localized("Enable or disable this condition rule")}>
                        <RegularInfoCircleIcon size="sm" color="colorTextWeakest" />
                      </Tooltip>
                    </Flex>
                    <Input
                      name="name"
                      labelText={localized("Name")}
                      placeholder={localized("Enter name")}
                      value={name}
                      errorMessage={validation.message.name}
                      onChange={onChange}
                      onBlur={() => validateForm(["name"])}
                      maxLength={50}
                      required
                    />
                  </Flex>
                ),
              },
              {
                label: localized("Ticket creation"),
                hasError: ticketCreateError,
                required: true,
                renderer: () => (
                  <TicketCreate
                    {...{
                      onChange,
                      values,
                      validation,
                      ticketFormsOptions,
                      readonly,
                    }}
                  />
                ),
              },
              ...(isConditionRule
                ? [
                    {
                      label: localized("Condition reset"),
                      hasError: conditionResetError,
                      required: true,
                      renderer: () => (
                        <ConditionReset
                          {...{
                            values,
                            validation,
                            onChange,
                            readonly,
                          }}
                        />
                      ),
                    },
                    {
                      label: localized("Condition retrigger"),
                      hasError: conditionRetriggerError,
                      required: true,
                      renderer: () => (
                        <ConditionRetrigger
                          {...{
                            values,
                            validation,
                            onChange,
                            validateFormFields,
                            readonly,
                          }}
                        />
                      ),
                    },
                  ]
                : []),
            ]}
          />
        )}
      </Flex>
    </Modal>
  )
})

export default connect(null, { fetchTicketStatuses: _fetchTicketStatuses })(TicketRulesetModal)
