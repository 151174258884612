import { useCallback } from "react"
import { prop } from "ramda"
import { localized } from "js/includes/common/utils"
import { getTagErrors } from "js/includes/ticketing/commonMethods"

export const useTagErrors = tagList =>
  useCallback(
    newTag => {
      const tagErrors = getTagErrors(newTag)
      const tagAlreadySelected = tagList.some(tag => (prop("name", tag) || tag) === newTag)

      const isInvalid = tagErrors || tagAlreadySelected
      return (
        isInvalid && [
          ...(tagAlreadySelected ? [localized("Tag is already selected")] : []),
          ...(tagErrors ? tagErrors : []),
        ]
      )
    },
    [tagList],
  )
