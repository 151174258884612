import { SearchableSelect } from "@ninjaone/components"

import { useMountedState } from "js/includes/common/hooks"
import { reportErrorAndShowMessage, showErrorMessage } from "js/includes/common/utils"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import { getTagErrors } from "js/includes/ticketing/commonMethods"
import { createTag } from "js/includes/common/client"

export const TagsEditor = ({
  selected: _selected,
  tags,
  onChange,
  disableEditor,
  onFocus,
  ariaLabel,
  errorMessage,
}) => {
  const selected = _selected || []
  const [isCreating, setIsCreating] = useMountedState(false)

  const onCreate = async newTag => {
    if (isCreating) {
      return
    }

    const tagErrors = getTagErrors(newTag)
    if (tagErrors) {
      tagErrors.forEach(errorToken => {
        showErrorMessage(errorToken)
      })
      return
    }
    try {
      setIsCreating(true)
      await createTag(newTag)

      onChange([...selected, newTag])
    } catch (error) {
      return reportErrorAndShowMessage(error, localizationKey("Error creating tag"))
    } finally {
      setIsCreating(false)
    }
  }

  return (
    <SearchableSelect
      ariaLabel={ariaLabel}
      options={tags}
      isMulti
      value={selected}
      comboboxProps={{
        creatable: true,
        onCreate,
      }}
      onChange={onChange}
      errorMessage={errorMessage}
      hideClearSelectionButton
    />
  )
}
