import PropTypes from "prop-types"

import { AlertMessage } from "@ninjaone/components"

import { localizationKey } from "js/includes/common/utils"
import { Flex } from "js/includes/components/Styled"
import CommonActions from "js/includes/ticketing/TriggerEditor/Actions"

import { LoadingWrapper } from "./LoadingWrapper"
import { actionsType, notificationChannelsType, tagsType, triggerType } from "./propTypes"

const translationTokens = {
  removeRule: {
    labelToken: localizationKey("Remove action"),
  },
  addRule: {
    labelToken: localizationKey("Add action"),
  },
}

const shouldRefreshTags = (actions, tags) =>
  actions.some(
    ({ field, value }) =>
      ["add_tags", "set_tags", "remove_tags"].includes(field) &&
      value?.some(current => tags.findIndex(tag => tag.name === current) === -1),
  )

export const Actions = ({
  isLoading,
  actions,
  onChange,
  type,
  getValidations,
  tags,
  fetchTags,
  isFormSubmitted,
  notificationChannels,
  validationMessage,
}) => {
  return (
    <LoadingWrapper {...{ isLoading }}>
      <Flex flexDirection="column" height="100%" justifyContent="space-between" padding="0 1px">
        <CommonActions
          query={actions}
          onQueryChange={actions => {
            shouldRefreshTags(actions, tags) && fetchTags({ refreshTags: true })
            return onChange("actions", actions)
          }}
          type={type}
          getValidations={getValidations}
          tags={tags}
          displayErrors={isFormSubmitted}
          translationTokens={translationTokens}
          notificationChannels={notificationChannels || []}
        />
        {validationMessage && isFormSubmitted && <AlertMessage variant="danger">{validationMessage}</AlertMessage>}
      </Flex>
    </LoadingWrapper>
  )
}

Actions.propTypes = {
  isLoading: PropTypes.bool,
  actions: actionsType.isRequired,
  onChange: PropTypes.func.isRequired,
  type: triggerType.isRequired,
  getValidations: PropTypes.func.isRequired,
  tags: tagsType.isRequired,
  fetchTags: PropTypes.func.isRequired,
  isFormSubmitted: PropTypes.bool,
  notificationChannels: notificationChannelsType,
  validationMessage: PropTypes.string,
}
