import fastDeepEqual from "fast-deep-equal"
import { useCallback, useEffect } from "react"
import qs from "qs"

import { useMountedState } from "@ninjaone/utils"
import {
  fetchJson,
  arrayToMapWithKey,
  getAsArray,
  localizationKey,
  reportErrorAndShowMessage,
} from "js/includes/common/utils"

export const useTags = ({ shouldQuery }) => {
  const [tagsData, setTagsData] = useMountedState({ tags: [], fetchingTags: shouldQuery })

  const fetchTags = useCallback(
    async ({ refreshTags } = {}) => {
      setTagsData(prevState => ({ ...prevState, fetchingTags: !refreshTags }))
      try {
        const _qs = qs.stringify(
          {
            pageSize: 0,
            sortDirection: "ASC",
          },
          { addQueryPrefix: true },
        )
        const payload = await fetchJson(`/ticketing/tags${_qs}`)
        setTagsData(prevState => ({
          ...prevState,
          tags: payload,
        }))
      } catch (error) {
        reportErrorAndShowMessage(error, localizationKey("Error while fetching tags"))
      } finally {
        setTagsData(prevState => ({ ...prevState, fetchingTags: false }))
      }
    },
    [setTagsData],
  )

  useEffect(() => {
    shouldQuery && fetchTags()
  }, [fetchTags, shouldQuery])

  return { ...tagsData, fetchTags }
}

const getEmptyValue = ({ isMulti }) => (isMulti ? [] : null)

const getValue = ({ value, isMulti }) => {
  let newValue = value
  if (!value || (isMulti && !Array.isArray(newValue)) || (!isMulti && Array.isArray(newValue))) {
    newValue = getEmptyValue({ isMulti })
  }
  return newValue
}

const getSelectionData = ({ value: _value, isMulti, valueKey }) => {
  const value = getValue({ value: _value, isMulti })
  return {
    value,
    selectionMap: arrayToMapWithKey(valueKey, getAsArray(value)),
    isMulti,
    valueKey,
  }
}

export const useSearchableDropdownValue = ({ value, isMulti, valueKey = "id" }) => {
  const [selectionData, setSelectionData] = useMountedState(() => getSelectionData({ isMulti, valueKey, value }))
  if (
    selectionData.isMulti !== isMulti ||
    selectionData.valueKey !== valueKey ||
    !fastDeepEqual(getValue({ value, isMulti }), selectionData.value)
  ) {
    /*
     * This is in charge of setting isMulti and value that are props of AppUsersAndContactsDropdown.
     * It's important because isMulti and value should change at the same time. Passing, for example,
     * isMulti: true and value: {} breaks that component.
     */
    setSelectionData(getSelectionData({ isMulti, value, valueKey }))
  }

  return {
    isMulti: selectionData.isMulti,
    value: selectionData.value,
    selectionMap: selectionData.selectionMap,
  }
}
