import styled from "@emotion/styled"

import { GripLinesIcon, RemoveIcon } from "@ninjaone/icons"
import { sizer } from "@ninjaone/utils"
import { IconButton } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"

import DragableList from "js/includes/components/DragableList"
import { Box, Flex } from "js/includes/components/Styled"
import { isNotNilOrEmpty } from "js/includes/common/utils"

const StyledContainer = styled.div`
  border-radius: ${tokens.borderRadius[1]};
  background-color: ${({ theme }) => theme.colorBackgroundSubtle};
`

const StyledDraggable = styled.div`
    flex: auto;
    max-height: ${({ maxHeight }) => maxHeight ?? "initial"};
    min-height: ${({ minHeight }) => minHeight ?? "initial"};
    ${({ height }) => height && `height: ${height};`}
    padding: ${tokens.spacing[2]};
    overflow-y: auto;
    cursor: ${({ isDisabled }) => !isDisabled && "grab"};
`

const StyledDraggableItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${sizer(2, 4)};
  margin-top: ${tokens.spacing[2]};
  border: 1px solid ${({ theme }) => theme.colorBorderWeak};
  border-radius: ${tokens.borderRadius[1]};
  background-color: ${({ theme }) => theme.colorBackgroundWidget};
  font-size: ${tokens.typography.fontSize.body};
  color: ${({ theme }) => theme.colorTextWeak};

  &:hover {
    background-color: ${({ theme, isDisabled }) => !isDisabled && theme.colorForegroundHover};
  }

  div[draggable="true"]:first-of-type > & {
    margin-top: 0;
  }

  &.dragging {
    cursor: grabbing;
  }
`

export default function StyledDraggableList({
  dragLabel,
  keyProp = "value",
  keyProps = [], // ["value", "id", "tempId"]
  elements,
  onChange,
  onRemoveElement,
  renderElement,
  renderActions,
  noRowsLabel,
  height,
  maxHeight,
  minHeight,
  listBottomRef,
  fadeAtBottom,
  isDisabled,
  loading,
}) {
  return (
    <StyledContainer>
      <StyledDraggable {...{ height, maxHeight, minHeight, isDisabled }}>
        <DragableList
          {...{
            keyProp,
            keyProps,
            elements,
            onChange,
            isDisabled,
            noRowsLabel,
            onRemoveElement,
            loading,
            renderElement: (section, removeSection, isDragging, index) => {
              const { isRemovable = true } = section
              return (
                <StyledDraggableItem className={isDragging ? "dragging" : ""} tabIndex={-1} isDisabled={isDisabled}>
                  <Flex alignItems="center" flex="1" minWidth="1px" minHeight="32px">
                    <Box marginRight={tokens.spacing[3]}>
                      {!isDisabled && <GripLinesIcon size="sm" color="colorTextWeak" />}
                    </Box>
                    <Box flex="1" minWidth="1px">
                      {renderElement(section)}
                    </Box>
                  </Flex>
                  <Flex>
                    {renderActions?.(section, index)}
                    {isRemovable && !isDisabled && (
                      <IconButton
                        size="sm"
                        handleClick={e => {
                          let element

                          if (keyProp) {
                            element = section[keyProp]
                          }

                          if (keyProps?.length) {
                            keyProps.forEach(_key => {
                              if (isNotNilOrEmpty(section[_key])) {
                                element = section[_key]
                              }
                            })
                          }

                          removeSection(e, element, section)
                        }}
                      >
                        <RemoveIcon color="colorTextWeak" />
                      </IconButton>
                    )}
                  </Flex>
                </StyledDraggableItem>
              )
            },
          }}
        />
      </StyledDraggable>
    </StyledContainer>
  )
}
