import { memo } from "react"
import { find, map, omit } from "ramda"

import { createTag } from "js/includes/common/client"
import { reportErrorAndShowMessage, showErrorMessage } from "js/includes/common/utils"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import { Box } from "js/includes/components/Styled"
import { useTagErrors } from "js/includes/ticketing/useTagErrors"

const findNew = ({ isNew }) => isNew
const genList = map(name => ({ value: name, name }))
const removeNewKey = map(omit("isNew"))

const TagsInput = ({ selected, onChange, disableEditor, onFocus, ariaAttributes, ariaInputAttributes }) => {
  const getTagErrors = useTagErrors(selected)

  const handleChange = async values => {
    const newTag = find(findNew, values || [])
    if (newTag) {
      const tagErrors = getTagErrors(newTag.value)

      if (!tagErrors) {
        try {
          await createTag(newTag.value)
        } catch (error) {
          return reportErrorAndShowMessage(error, localizationKey("Error creating tag"))
        }
      } else {
        return tagErrors.forEach(errorToken => {
          showErrorMessage(errorToken)
        })
      }
    }
    onChange(newTag ? removeNewKey(values) : values)
  }

  const selectedTags = genList(selected)

  return (
    <SearchableDropDown
      {...{
        endpoint: "/ticketing/tags",
        pageSize: 50,
        searchParams: ({ query }) => ({ sortDirection: "ASC", ...(query && { searchCriteria: query }) }),
        lastItemKey: "name",
        getAnchorNaturalId: ({ anchorNaturalId }) => ({ anchorName: anchorNaturalId }),
        responseParser: payload => payload.map(row => ({ value: row.name, ...row })),
        searchableKey: "name",
        value: selectedTags,
        width: "100%",
        isMulti: true,
        isCreatable: true,
        placeholderToken: localizationKey("Tags"),
        onSelect: handleChange,
        openOnFocus: true,
        shouldCreate: tag => {
          const errors = getTagErrors(tag)

          errors &&
            errors.forEach(errorToken => {
              showErrorMessage(errorToken)
            })

          return !errors
        },
        keepDropdownInView: true,
        disabled: disableEditor,
        rowRenderer: row => (
          <Box overflow="hidden">
            <p className="no-margin text-ellipsis line-height-initial">{row.name}</p>
          </Box>
        ),
        ariaAttributes,
        ariaInputAttributes,
      }}
    />
  )
}

TagsInput.defaultProps = {
  onChange: () => {},
}

export default memo(TagsInput)
